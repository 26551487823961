import { Modal } from '../../components/Modal';
import {
    Carousel,
    Slide,
    SliderControlButton,
    SliderController,
} from '../../components/Carousel';
import Logo from '../../assets/splash/logo.webp';
import BookCoverImg from '../../assets/onboarding/scan-book.webp';
import clsx from 'clsx';
import Slide1BG from '../../assets/onboarding/slide-1-bg.webp';
import Slide2BG from '../../assets/onboarding/slide-2-bg.webp';
type OnboardingModalsProps = {
    onClose: () => void;
};

export const OnboardingModals = ({ onClose }: OnboardingModalsProps) => {
    const sliderController: SliderController = {
        post: () => {},
    };

    return (
        <Modal
            className={clsx(
                'text-white centered bg-cover rounded-none max-h-[600px] h-full max-w-[390px]',
            )}
        >
            <Carousel controller={sliderController}>
                {/* <Slide>
                    <div className="relative w-full h-full flex flex-col justify-between pb-[70px] pt-14 px-8">
                        <img
                            src={Slide1BG}
                            className={clsx(
                                'absolute -z-20 inset-0 w-full h-full bg-[url(./assets/onboarding/slide-1-bg.webp)]',
                            )}
                        />
                        <img src={Logo} />
                        <SliderControlButton
                            sliderController={sliderController}
                            message="next"
                            text="NEXT"
                            className="button-default"
                        />
                    </div>
                </Slide> */}
                <Slide>
                    <div className="relative w-full h-full flex flex-col justify-between pb-[70px] pt-14 px-8">
                        <img
                            src={Slide2BG}
                            className={clsx(
                                'absolute inset-0 -z-20 h-full bg-[url(./assets/onboarding/slide-2-bg.webp)]',
                            )}
                        />
                        <img
                            src={BookCoverImg}
                            className="max-w-[180px] w-full"
                        />
                        <p className="text-base leading-[20px]">
                            Point your device camera at The Next Dimension book
                            cover to see it come alive with Augmented Reality.
                        </p>
                        <SliderControlButton
                            sliderController={sliderController}
                            message="undefined"
                            text="BEGIN"
                            onClick={onClose}
                            className="button-default"
                        />
                    </div>
                </Slide>
            </Carousel>
        </Modal>
    );
};
