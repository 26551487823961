import { ReactNode } from 'preact/compat';

type SlideProps = {
    children: ReactNode;
};

export const Slide = ({ children }: SlideProps) => {
    return (
        <div className="embla__slide flex-[0_0_var(--slide-size)] min-w-0 pl-[var(--slide-spacing)]">
            {children}
        </div>
    );
};
