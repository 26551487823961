import { clsx } from 'clsx';
import { SliderController } from './Carousel';
import { ReactNode } from 'preact/compat';

type SliderControlButtonProps = {
    sliderController: SliderController;
    message: string;
    className?: string;
    text?: string;
    onClick?: () => void;
    children?: ReactNode;
};

export const SliderControlButton = ({
    sliderController,
    message,
    className,
    text,
    onClick,
    children,
}: SliderControlButtonProps) => {
    return (
        <button
            className={clsx('mx-auto', className)}
            onClick={() => {
                sliderController.post(message);
                if (onClick) onClick();
            }}
        >
            {text}
            {children}
        </button>
    );
};
