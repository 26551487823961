import { route } from "preact-router";

export const NotFound = () => {
    const handleGoHome = () => {
        route('/');
      };
    return (
        <div className="absolute inset-0 z-[1000] justify-between text-white h-full w-full bg-[#181146] bg-cover flex items-center flex-col p-12">
            <div className="w-full h-full  flex flex-col justify-center items-center">
                <div>
                    <h2 className="text-[128px] text-center text-white font-zurich ">
                        404
                    </h2>
                    <h2 className="text-3xl text-center font-helvetica-bold ">
                        Oops!
                    </h2>
                    <h2 className="text-xl text-center font-helvetica-light ">
                        Page not found!
                    </h2>
                   
                </div>
            </div>
            <div className="w-full flex flex-col justify-end items-center gap-4">
                
                <button
                    className="border-2 border-white bg-cover font-button  text-sm w-[250px] h-[58px] pointer-events-auto"
                    onClick={handleGoHome}
                >
                    BACK TO EXPERIENCE
                </button>
            </div>
        </div>
    );
};
