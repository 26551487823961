type ErrorBoundaryProps = {
    onResetError: () => void;
    error: string;
};
export const ErrorBoundary = ({
    onResetError,
    error = '',
}: ErrorBoundaryProps) => {
    return (
        <div className="absolute inset-0 z-[1000] justify-between text-white h-full w-full bg-[#181146] bg-cover flex items-center flex-col p-12">
            <div className="w-full h-full  flex flex-col justify-center items-center">
                <div>
                    <h2 className="text-[128px] text-center text-white font-zurich ">
                        404
                    </h2>
                    <h2 className="text-3xl text-center font-helvetica-bold ">
                        Oops!
                    </h2>
                    <h2 className="text-xl text-center font-helvetica-light ">
                        Something went wrong!
                    </h2>
                    <p className="text-lg text-center pt-6 font-helvetica-light ">
                        Reason: {error ?? ''}
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-col justify-end items-center gap-4">
                <button className="border-2 border-white bg-cover font-button text-sm w-[250px] h-[58px] pointer-events-auto">
                    <a
                        href="https://forms.gle/Qhr1NxWTa6tA3MQE9"
                        target="_blank"
                        rel="noreferrer"
                    >
                        REPORT BUG
                    </a>
                </button>
                <button
                    className="border-2 border-white bg-cover font-button  text-sm w-[250px] h-[58px] pointer-events-auto"
                    onClick={onResetError}
                >
                    BACK TO EXPERIENCE
                </button>
            </div>
        </div>
    );
};
