import clsx from 'clsx';
import { VideoRecorderResult } from './useVideoRecorder';
import { useCallback } from 'preact/hooks';
import { RendererApi } from '../../renderer';
export * from './useVideoRecorder';

type RecordingButtonProps = {
    recordingState: VideoRecorderResult;
    renderer: RendererApi;
};
/**
 * All of the video recording logic is implemented within the `useVideoRecorder` hook.
 * This component is basically just styling.
 */
let recordingTimeout: number | undefined;

export function RecordingButton({
    recordingState,
    renderer,
}: RecordingButtonProps) {
    const handleButtonClick = useCallback(() => {
        if (recordingState.state === 'none') {
            recordingState.startRecording();
        } else if (recordingState.state === 'recording') {
            recordingState.stopRecording();
        } else if (recordingState.state === 'ready') {
            recordingState.clear();
        }
    }, [recordingState]);

    // const handleButtonClick = useCallback(() => {
    //     if (recordingState.state === 'none') {
    //         recordingTimeout = window.setTimeout(() => {
    //             if (recordingTimeout) {
    //                 recordingState.startRecording();
    //             }
    //         }, 300);
    //     }
    // }, [recordingState, renderer]);

    // const handleTouchEnd = () => {
    //     if (recordingState.state === 'none') {
    //         if (recordingTimeout) {
    //             window.clearTimeout(recordingTimeout);
    //             recordingTimeout = undefined;
    //             renderer.takeScreenshot();
    //         }
    //     } else if (recordingState.state === 'recording') {
    //         recordingState.stopRecording();
    //     } else if (recordingState.state === 'ready') {
    //         recordingState.clear();
    //     }
    // };

    return (
        <>
            <div
                className="fixed bottom-[30px] left-1/2 -translate-x-1/2 max-w-[94px] max-h-[94px] transition-opacity duration-500 ease-in-out w-full h-full z-[1] pointer-events-auto touch-manipulation"
                data-recording-state={recordingState.state}
                onTouchStart={handleButtonClick}
            >
                <>
                    <svg
                        viewBox="0 0 38 38"
                        id="progressContainer"
                        className={clsx(
                            'record-shadow block absolute top-0 left-0 w-full h-full stroke-2 stroke-white',
                            {
                                'opacity-1 scale-[1] ':
                                    recordingState.state === 'recording',
                                'scale-[0.9] transition-[0.3s_transform,_0.3s_opacity] !duration-300':
                                    recordingState.state !== 'recording',
                            },
                        )}
                    >
                        <circle
                            id="progressTrack"
                            className={clsx(
                                'fill-[rgba(0,0,0,0.2)] stroke-[1px] xstroke-white',
                            )}
                            r="16"
                            cx="19"
                            cy="19"
                        ></circle>
                        <circle
                            id="progressBar"
                            className={clsx(
                                'progressBar fill-transparent scale-[0.6] stroke-[10px] transition-[0] origin-center stroke-[#FF3434] rotate-[-90deg]',
                                {
                                    'opacity-[0.5]':
                                        recordingState.state === 'recording',
                                    'opacity-0':
                                        recordingState.state !== 'recording',
                                },
                            )}
                            style={{
                                strokeDashoffset:
                                    recordingState.state === 'recording'
                                        ? `${100 - Number(recordingState.progress.toFixed(2)) * 100}`
                                        : 100,
                            }}
                            r="16"
                            cx="19"
                            cy="19"
                        ></circle>
                        <circle
                            id="loadingCircleRecording"
                            className={clsx(
                                'fill-transparent stroke-[1px] stroke-coral-red-500 origin-center',
                                {
                                    'opacity-1 animate-[record-button-spin_1.1s_linear_infinite_both] ease-in-out':
                                        recordingState.state === 'encoding',
                                    'opacity-0':
                                        recordingState.state !== 'encoding',
                                },
                            )}
                            r="16"
                            cx="19"
                            cy="19"
                        ></circle>
                    </svg>
                    <button
                        className={clsx(
                            'absolute top-0 left-0 origin-center transition-[0.3s_border-radius,_0.3s_transform] w-full h-full rounded-full text-transparent',
                            {
                                'scale-[0.45] bg-[#FF0000]':
                                    recordingState.state === 'recording',
                                'scale-[0.3] bg-coral-red-500':
                                    recordingState.state !== 'recording',
                            },
                        )}
                    ></button>
                </>
            </div>
        </>
    );
}
