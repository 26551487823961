import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './CarouselDotButton';
import useEmblaCarousel from 'embla-carousel-react';
import clsx from 'clsx';
import { createPortal, ReactNode, useEffect, useRef } from 'preact/compat';

type CarouselProps = {
    slides?: number[];
    options?: EmblaOptionsType;
    controller: SliderController;
    children: ReactNode;
    onSelectedIndexChange?: (index: number) => void;
};

export type SliderController = {
    post: (message: string) => void;
};

export const Carousel = ({
    slides,
    options,
    controller,
    children,
    onSelectedIndexChange,
}: CarouselProps) => {
    const [carouselRef, emblaApi] = useEmblaCarousel(options);

    const emblaWrapper = useRef(null);

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);

    const post = (message: string) => {
        switch (message) {
            case 'next':
                onDotButtonClick(selectedIndex + 1);
                break;
            case 'reset':
                onDotButtonClick(0);
                break;
        }
    };

    controller.post = post;

    // syncs up index changes and sends them to the parent component
    useEffect(() => {
        if (onSelectedIndexChange) onSelectedIndexChange(selectedIndex);
    }, [selectedIndex]);

    return (
        <section
            ref={emblaWrapper}
            className="embla max-w-[48rem] h-full flex flex-col pointer-events-auto"
        >
            <div
                className={clsx('embla__viewport overflow-hidden basis-full pointer-events-auto')}
                ref={carouselRef}
            >
                <div className="embla__container pointer-events-auto h-full flex touch-pan-y touch-pinch-zoom ml-[calc(var(--slide-spacing)*-1)]">
                    {children}
                </div>
                {emblaWrapper.current &&
                    createPortal(
                        <>
                            {Array.from(children).length > 1 && (
                                <div className="embla__controls flex absolute bottom-7 basis-[10%] items-center w-full justify-center gap-5 xmt-4 xabsolute xbottom-8 xleft-1/2 x-translate-x-1/2">
                                    <div className="embla__dots flex flex-wrap justify-end items-center mr-[calc((2.6rem-1.4rem)/2*-1)]">
                                        {scrollSnaps.map((_, index) => (
                                            <DotButton
                                                key={index}
                                                onClick={() =>
                                                    onDotButtonClick(index)
                                                }
                                                className={'embla__dot appearance-none bg-transparent touch-manipulation xinline-flex cursor-pointer border-0 p-0 m-0 w-5 h-5 no-underline flex items-center justify-center after:w-3 after:h-3 after:rounded-full after:flex after:items-center after:content-[""]'.concat(
                                                    index === selectedIndex
                                                        ? ' embla__dot--selected after:bg-white'
                                                        : ' after:bg-[rgba(255,255,255,0.2)]',
                                                )}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>,
                        emblaWrapper.current,
                    )}
            </div>
        </section>
    );
};
