import 'preact/debug';
import { render } from 'preact';
import { App } from './pages/app.tsx';
import { Provider } from 'jotai';
import { imageTargetStore } from './renderer/8thwall/atoms';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import Router from 'preact-router'
import { NotFound } from './pages/NotFound.tsx';

if (import.meta.env.PROD) {
    ReactGA.initialize('G-JPLBN1M1QZ');
    Sentry.init({
        dsn: 'https://dc567dc26a6926f81589b5b539df3b9c@o1388406.ingest.us.sentry.io/4507547359772672',
        integrations: [],
    });
}

render(
    <Provider store={imageTargetStore}>
        <Router>
        <App path="/" />
        <NotFound default />
    </Router>
    </Provider>,
    document.getElementById('app')!,
);
