import { Modal } from '../../components/Modal';
import { ModalOverlay } from '../../components/ModalOverlay';
import {
    Carousel,
    Slide,
    SliderControlButton,
    SliderController,
} from '../../components/Carousel';

import ProfileImg from '../../assets/info/profile.png';
import LogoImg from '../../assets/info/logo.png';

type InfoModalProps = {
    onClose: () => void;
};

export const InfoModal = ({ onClose }: InfoModalProps) => {
    const sliderController: SliderController = {
        post: () => {},
    };
    return (
        <ModalOverlay>
            <Modal className="bg-[#181146] bg-cover text-white centered max-h-[90%] h-fit overflow-scroll max-w-[390px] rounded-none">
                {/* <Carousel controller={sliderController}> */}
                <Slide>
                    <SliderControlButton
                        sliderController={sliderController}
                        message="undefined"
                        onClick={onClose}
                        className="absolute right-5 top-5"
                    >
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1"
                                y="1"
                                width="32"
                                height="32"
                                rx="16"
                                stroke="white"
                                strokeWidth="2"
                            />
                            <path
                                d="M17.1985 18.4737L13.1902 22.482L11.9172 21.2023L15.9188 17.2007L11.9172 13.1924L13.1902 11.9195L17.1985 15.9211L21.2 11.9195L22.473 13.1924L18.4714 17.2007L22.473 21.2023L21.2 22.4752L17.1985 18.4737Z"
                                fill="white"
                            />
                        </svg>
                    </SliderControlButton>
                    <div className="p-5 flex flex-col gap-7">
                        <div className="flex flex-col gap-6">
                            <img src={ProfileImg} className="max-w-20 w-full" />
                            <p className="text-[15px] leading-[18.6px]">
                                Tom Emrich is the author of The Next Dimension,
                                your guide to using augmented reality for
                                business growth. With over 15 years in spatial
                                computing, Tom&apos;s insights and expertise
                                make this book a must to help ready your
                                business for the next wave of computing.
                            </p>
                            <div className="flex gap-5 text-base font-bold text-[#C9B484] tracking-none underline underline-offset-2">
                                <a
                                    href="https://a.co/d/9ThCPc7"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    The Next Dimension
                                </a>
                                <a
                                    href="https://www.linkedin.com/in/thomasemrich/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    LinkedIn
                                </a>
                            </div>
                            <a
                                href="https://ai.thenextdimensionbook.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="button-default max-w-full xs:text-[14px] text-[12px] px-2"
                            >
                                Chat with an AI about this book{' '}
                                <svg
                                    width="22"
                                    height="12"
                                    viewBox="0 0 22 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-2"
                                >
                                    <path
                                        d="M21.5303 6.53033C21.8232 6.23744 21.8232 5.76256 21.5303 5.46967L16.7574 0.696699C16.4645 0.403806 15.9896 0.403806 15.6967 0.696699C15.4038 0.989593 15.4038 1.46447 15.6967 1.75736L19.9393 6L15.6967 10.2426C15.4038 10.5355 15.4038 11.0104 15.6967 11.3033C15.9896 11.5962 16.4645 11.5962 16.7574 11.3033L21.5303 6.53033ZM0 6.75H21V5.25H0V6.75Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div>
                            <svg
                                width="350"
                                height="1"
                                viewBox="0 0 350 1"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line
                                    opacity="0.4"
                                    x1="4.37114e-08"
                                    y1="0.5"
                                    x2="350"
                                    y2="0.500031"
                                    stroke="white"
                                    strokeDasharray="1 2"
                                />
                            </svg>
                        </div>
                        <div className="flex flex-col gap-6">
                            <img src={LogoImg} className="max-w-20 w-full" />
                            <p className="text-[15px] leading-[18.6px]">
                                This Augmented Reality experience was crafted by
                                EyeJack, an Art and Technology company that
                                creates Experiences.
                            </p>
                            <div className="flex gap-5 text-base font-bold text-[#C9B484] tracking-none underline underline-offset-2">
                                <a
                                    href="https://www.eyejack.io/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    EyeJack.io
                                </a>
                            </div>
                        </div>
                    </div>
                </Slide>
                {/* </Carousel> */}
            </Modal>
        </ModalOverlay>
    );
};
