import { useState } from 'preact/hooks';
import { Modal } from '../../components/Modal';
import { ModalOverlay } from '../../components/ModalOverlay';
import { VideoRecorderResult } from '../recording-button';
import clsx from 'clsx';

export type MediaPreviewProps = {
    recordingState: VideoRecorderResult;
    onVideoCleared: () => void;
};

export function MediaPreview({
    recordingState,
    onVideoCleared,
}: MediaPreviewProps) {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const onVideoDataLoaded = () => {
        setVideoLoaded(true);
    };

    if (recordingState.state !== 'ready') return null;
    return (
        <ModalOverlay className="z-[200]">
            <Modal
                className={clsx(
                    'flex flex-col gap-4 items-center xjustify-center xp-4 centered  ',
                )}
            >
                <div className="max-h-full max-w-full overflow-y-auto h-full flex flex-col gap-4 items-center justify-center">
                    <div className="relative flex flex-col items-center border-white z-[100] border-[3px] rounded-[22px] max-w-[70vw] max-h-[70%] overflow-auto w-fit">
                        <button
                            className="absolute top-3 right-3 z-[100]"
                            onClick={onVideoCleared}
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="0.989136"
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="black"
                                    fillOpacity="0.1"
                                />
                                <rect
                                    x="1"
                                    y="0.989136"
                                    width="32"
                                    height="32"
                                    rx="16"
                                    stroke="white"
                                />
                                <path
                                    d="M17.1985 18.4629L13.1902 22.4712L11.9172 21.1915L15.9188 17.1899L11.9172 13.1816L13.1902 11.9087L17.1985 15.9103L21.2 11.9087L22.473 13.1816L18.4714 17.1899L22.473 21.1915L21.2 22.4644L17.1985 18.4629Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        <video
                            src={recordingState.videoUrl}
                            className={clsx(
                                'object-cover rounded-[20px] w-auto h-auto max-h-full',
                                {
                                    'animate-skeleton-load bg-gray-300 ':
                                        !videoLoaded,
                                },
                            )}
                            playsInline
                            autoPlay
                            muted
                            loop
                            onLoadedData={onVideoDataLoaded}
                        />
                    </div>
                    <p className="text-white text-center">
                        Check out this video of The Next Dimension book coming
                        to life with augmented reality! Get yours at{' '}
                        <a
                            href="https://thenextdimensionbook.com/"
                            target="_blank"
                            className="underline hover:opacity-70 xactive:opacity-70"
                            rel="noreferrer"
                        >
                            thenextdimensionbook.com
                        </a>
                        .
                    </p>
                    <button
                        className="xmt-16 text-white max-w-[55vw] button-default "
                        onClick={() =>
                            recordingState.download(
                                'ar_the_next_dimension_book_video.mp4',
                            )
                        }
                    >
                        SHARE
                    </button>
                </div>
            </Modal>
        </ModalOverlay>
    );
}
