import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { ModalOverlay } from './ModalOverlay';

type ModalProps = {
    width?: string;
    height?: string;
    className?: string;
    children: ReactNode;
};

export const Modal = ({ className, children }: ModalProps) => {
    return (
        <ModalOverlay>
            <div
                className={clsx(
                    'absolute bg-cover z-50 overflow-hidden max-w-[90%] max-h-[90%] w-full h-full rounded-xl centered',
                    className,
                )}
                data-cmp="Modal"
            >
                {children}
            </div>
        </ModalOverlay>
    );
};
