const { dat } = window;

import {
    OutputPass,
    ShaderPass,
    TexturePass,
    EffectComposer,
    RGBELoader,
    RenderPass,
    GammaCorrectionShader,
    ACESFilmicToneMappingShader,
} from 'three/examples/jsm/Addons.js';

import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Vector2,
    MathUtils,
    DataTexture,
    RGBAFormat,
    Color,
    WebGLRenderTarget,
    ReinhardToneMapping,
    AmbientLight,
    Texture,
    SRGBColorSpace,
    PMREMGenerator,
    ACESFilmicToneMapping,
    EquirectangularReflectionMapping,
    NoToneMapping,
    ShaderMaterial,
    HalfFloatType,
    LinearSRGBColorSpace,
} from 'three';
import { UnrealBloomPass } from './AlphaUnrealBloomPass.js';
import * as shaderHtml from './shaders.html?raw';

const params = {
    threshold: 0.4,
    strength: 0.4,
    radius: 0,
    exposure: 1,
};

export const threejsPipelineModule = () => {
    let scene3;
    let isSetup = false;
    let sceneTarget;

    let cameraFeedRenderer;
    const cameraTexture = new Texture();

    let videoWidth_;
    let videoHeight_;
    let canvasWidth_ = window.innerWidth;
    let canvasHeight_ = window.innerHeight;
    let width;
    let height;

    // add shaders to the document
    document.body.insertAdjacentHTML('beforeend', shaderHtml.default);

    const xrScene = () => scene3;

    const updateSize = ({
        videoWidth,
        videoHeight,
        canvasWidth,
        canvasHeight,
        GLctx,
    }) => {
        width = canvasWidth;
        height = canvasHeight;

        cameraFeedRenderer = window.XR8.GlTextureRenderer.create({
            GLctx,
            toTexture: { width, height },
            flipY: false,
            mirroredDisplay: false,
        });

        canvasWidth_ = canvasWidth;
        canvasHeight_ = canvasHeight;
        videoWidth_ = videoWidth;
        videoHeight_ = videoHeight;
    };

    const trySetup = async ({
        canvas,
        canvasWidth,
        canvasHeight,
        GLctx,
        videoWidth,
        videoHeight,
    }) => {
        if (isSetup) {
            return;
        }
        isSetup = true;

        updateSize({
            videoWidth,
            videoHeight,
            canvasWidth,
            canvasHeight,
            GLctx,
        });

        width = canvasWidth;
        height = canvasHeight;

        const scene = new Scene();
        const camera = new PerspectiveCamera(
            60.0 /* initial field of view; will get set based on device info later. */,
            canvasWidth / canvasHeight,
            0.1,
            1000,
        );
        scene.add(camera);

        const renderer = new WebGLRenderer({
            canvas,
            context: GLctx,
            alpha: true,
            antialias: true,
        });
        renderer.toneMappingExposure = params.exposure;
        renderer.toneMapping = ACESFilmicToneMapping;

        sceneTarget = new WebGLRenderTarget(canvasWidth_, canvasHeight_, {
            generateMipmaps: false,
            // this was necessary for toneMapping to work in three.js >=r153
            type: HalfFloatType,
        });

        // Bloom Composer
        const bloomComposer = new EffectComposer(renderer);
        bloomComposer.renderToScreen = false;
        bloomComposer.setSize(canvasWidth_, canvasHeight_);
        bloomComposer.addPass(new RenderPass(scene, camera));

        // Copy scene into bloom
        const copyPass = new TexturePass(sceneTarget.texture);
        bloomComposer.addPass(copyPass);

        // Bloom Pass
        const bloomPass = new UnrealBloomPass(
            new Vector2(canvasWidth_, canvasHeight_),
            1.5,
            0.4,
            0.85,
        );
        bloomPass.clearColor = new Color(0xffffff);
        bloomPass.threshold = params.threshold;
        bloomPass.strength = params.strength;
        bloomPass.radius = params.radius;
        bloomComposer.addPass(bloomPass);

        // Final composer
        const composer = new EffectComposer(renderer);
        composer.setSize(window.innerWidth, window.innerHeight);

        composer.addPass(copyPass);

        // Combine scene and camerafeed pass
        const combinePass = new ShaderPass({
            uniforms: {
                cameraTexture: { value: null },
                tDiffuse: { value: null },
                useAdditiveBlend: { value: false },
            },
            fragmentShader:
                document.getElementById('fragmentshader').textContent,
            vertexShader: document.getElementById('vertexshader').textContent,
        });
        combinePass.clear = false;
        combinePass.renderToScreen = true;
        combinePass.uniforms.cameraTexture = { value: cameraTexture };
        combinePass.uniforms.bloomTexture = {
            value: bloomPass.renderTargetsHorizontal[0].texture,
        };

        composer.addPass(new OutputPass());
        composer.addPass(combinePass);

        scene.add(new AmbientLight(0xffffff, 2));

        // // Load env maps
        // let emptyWarehouseMap, royalEsplanadeMap, street42Map;

        // new RGBELoader().load('/empty_warehouse_01_1k.hdr', function (texture) {
        //     texture.mapping = EquirectangularReflectionMapping;
        //     emptyWarehouseMap = texture;
        //     scene.environment = texture;
        // });

        // new RGBELoader().load('/royal_esplanade_1k.hdr', function (texture) {
        //     texture.mapping = EquirectangularReflectionMapping;
        //     royalEsplanadeMap = texture;
        // });

        // new RGBELoader().load('/42ND_STREET_Small.hdr', function (texture) {
        //     street42Map = texture;
        //     texture.mapping = EquirectangularReflectionMapping;
        // });
        // const gui = new dat.GUI({ width: 250 });
        // gui.add(params, 'exposure', 0.1, 2).onChange((value) => {
        //     renderer.toneMappingExposure = value ** 4;
        // });
        // gui.add(bloomPass, 'threshold', 0, 1);
        // gui.add(bloomPass, 'strength', 0, 3);
        // gui.add(bloomPass, 'radius', 0, 1);

        // const tonemaps = [
        //     'NoToneMapping',
        //     'ReinhardToneMapping',
        //     'ACESFilmicToneMapping',
        // ];
        // const state = {
        //     TONEMAP: 'ACESFilmicToneMapping',
        //     ENVMAP: 'EmptyWarehouse',
        // };
        // let guiTonemap = gui.add(state, 'TONEMAP', tonemaps).name('Tonemap');

        // guiTonemap.onChange(function (value) {
        //     if (value == 'NoToneMapping') {
        //         renderer.toneMapping = NoToneMapping;
        //         renderer.toneMappingExposure = 1.0;
        //         bloomPass.strength = 0.5;
        //         bloomPass.threshold = 0.9;
        //         bloomPass.radius = 0.5;
        //     } else if (value == 'ReinhardToneMapping') {
        //         renderer.toneMapping = ReinhardToneMapping;
        //         renderer.toneMappingExposure = Math.pow(params.exposure, 4.0);
        //         bloomPass.strength = 1.0;
        //         bloomPass.threshold = 0.6;
        //         bloomPass.radius = 0.5;
        //     } else if (value == 'ACESFilmicToneMapping') {
        //         renderer.toneMapping = ACESFilmicToneMapping;
        //         renderer.toneMappingExposure = Math.pow(params.exposure, 4.0);
        //         bloomPass.strength = 0.6;
        //         bloomPass.threshold = 0.9;
        //         bloomPass.radius = 0.0;
        //     }
        // });

        // const envmaps = ['EmptyWarehouse', 'RoyalEsplanade', '42ndStreet'];

        // let guiEnvmap = gui.add(state, 'ENVMAP', envmaps).name('Envmap');
        // guiEnvmap.onChange(function (value) {
        //     if (value == 'RoyalEsplanade') {
        //         scene.environment = royalEsplanadeMap;
        //     } else if (value == 'EmptyWarehouse') {
        //         scene.environment = emptyWarehouseMap;
        //     } else if (value == '42ndStreet') {
        //         scene.environment = street42Map;
        //     }
        // });

        window.scene3 = scene3;
        window.XR8.Threejs.xrScene = xrScene;

        scene3 = {
            scene,
            camera,
            renderer,
            bloomComposer,
            composer,
            canvasWidth,
            canvasHeight,
        };
    };

    return {
        name: 'customthreejs',
        onStart: (args) => trySetup(args),
        onDetach: () => {
            isSetup = false;
        },
        onUpdate: ({ processCpuResult }) => {
            const realitySource =
                processCpuResult.reality || processCpuResult.facecontroller;

            if (!realitySource) {
                return;
            }

            const { rotation, position, intrinsics } = realitySource;
            const { camera } = scene3;

            for (let i = 0; i < 16; i++) {
                camera.projectionMatrix.elements[i] = intrinsics[i];
            }

            // Fix for broken raycasting in r103 and higher. Related to:
            //   https://github.com/mrdoob/three.js/pull/15996
            // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
            // the inverse
            if (camera.projectionMatrixInverse) {
                camera.projectionMatrixInverse
                    .copy(camera.projectionMatrix)
                    .invert();
            }

            if (rotation) {
                camera.setRotationFromQuaternion(rotation);
            }
            if (position) {
                camera.position.set(position.x, position.y, position.z);
            }
            const { renderer, canvasWidth, canvasHeight } = scene3;
            const realityTexture =
                realitySource.realityTexture || realitySource.cameraFeedTexture;

            const texProps = renderer.properties.get(cameraTexture);
            texProps.__webglTexture = cameraFeedRenderer.render({
                renderTexture: realityTexture,
                viewport: window.XR8.GlTextureRenderer.fillTextureViewport(
                    videoWidth_,
                    videoHeight_,
                    canvasWidth_,
                    canvasHeight_,
                ),
            });
        },
        onCanvasSizeChange: ({
            canvasWidth,
            canvasHeight,
            videoWidth,
            videoHeight,
            GLctx,
        }) => {
            if (!isSetup) {
                return;
            }
            updateSize({
                videoWidth,
                videoHeight,
                canvasWidth,
                canvasHeight,
                GLctx,
            });

            const { renderer } = scene3;
            renderer.setSize(canvasWidth, canvasHeight);
            const pixelRatio = MathUtils.clamp(window.devicePixelRatio, 1, 2);
            renderer.pixelRatio = pixelRatio;

            // Update render pass sizes
            scene3.bloomComposer.setSize(
                canvasWidth * pixelRatio,
                canvasHeight * pixelRatio,
            );
            scene3.bloomComposer.passes.forEach((pass) => {
                if (pass.setSize) {
                    pass.setSize(
                        canvasWidth * pixelRatio,
                        canvasHeight * pixelRatio,
                    );
                }
            });
            scene3.composer.setSize(
                canvasWidth * pixelRatio,
                canvasHeight * pixelRatio,
            );
            scene3.composer.passes.forEach((pass) => {
                if (pass.setSize) {
                    pass.setSize(
                        canvasWidth * pixelRatio,
                        canvasHeight * pixelRatio,
                    );
                }
            });
            sceneTarget.setSize(
                canvasWidth * pixelRatio,
                canvasHeight * pixelRatio,
            );
        },
        onRender: () => {
            if (sceneTarget) {
                scene3.renderer.setRenderTarget(sceneTarget);
            }
            scene3.renderer.clear();
            scene3.renderer.clearDepth();
            scene3.renderer.render(scene3.scene, scene3.camera);
            scene3.renderer.setRenderTarget(null);

            scene3.bloomComposer.render();
            scene3.composer.render();
        },
        // Get a handle to the xr scene, camera, renderer, and composers
        xrScene,
    };
};
