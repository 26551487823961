import mitt, { Emitter } from 'mitt';
import { I8thWallImageTargetEventModel, init8thWall } from './8thwall';
import { drawWatermark } from './8thwall/watermark';
import { initImageTargetExperience } from './image-targets';
import {
    ImageTargetEvents,
    ImageTargetPipelineModuleResult,
} from './8thwall/image-target-pipeline-module';

const XR8_API_KEY =
    'wNczcg81hji21FCh4ZVfHE1yP99jGWUhsFIilB3RX1yY4KC33rnFBamvlJwio9Y6sG8Wyu';

/**
 * Events and API exposed by the renderer
 */
export type RendererEvents = {
    // When the content is loading this will update whenever the load progress changes.
    'content-load-progress': number;
    // When the content has finished loading this will be called.
    'content-loaded': void;
    // When 8thwall registers that recording has started
    'recording-started': void;
    // The recording progress, this event occurs every time a frame is captured
    'recording-progress': { elapsedSeconds: number; totalSeconds: number };
    // When 8thwall registers that recording has stopped. 8thwall will then begin encoding the vided into a real file.
    'recording-stopped': void;
    // When 8th wall has encoded the video it will send out a blob containing the video.
    'recording-ready': Blob;

    'on-show-target': void;
    'on-hide-target': void;
    'on-update-target': (ev: I8thWallImageTargetEventModel) => void;
    'on-targets-scanned': (ev: I8thWallImageTargetEventModel) => void;

    'resume-tracking': void;
    'pause-tracking': void;
};

export type RendererApi = {
    on: Emitter<RendererEvents>['on'];
    off: Emitter<RendererEvents>['off'];
    loadContent(path: string, audioPath?: string): Promise<void>;
    startRecording(): void;
    stopRecording(): void;
    pauseTracking(): void;
    resumeTracking(): void;
    pauseAudio(): void;
    resumeAudio(): void;
};

type RendererOptions = {
    watermarkImageUrl: string;
};

export async function initExperienceRenderer(
    canvas: HTMLCanvasElement,
    options: RendererOptions,
): Promise<RendererApi> {
    console.debug('initExperienceRenderer(canvas)', canvas);
    const emitter = mitt<RendererEvents>();

    const { mediaRecorder, module, scene, audio, camera } = await init8thWall(
        canvas,
        XR8_API_KEY,
        options,
    );
    
    const { loadArtwork } = initImageTargetExperience(
        module as ImageTargetPipelineModuleResult,
        scene,
        camera,
        audio,
    );
    const moduleEmitter = module.emitter as Emitter<ImageTargetEvents>;

    emitter.on('resume-tracking', () => {
        moduleEmitter.emit('resume-tracking');
    });
    emitter.on('pause-tracking', () => {
        moduleEmitter.emit('pause-tracking');
    });

    moduleEmitter.on('content-loaded', () => {
        emitter.emit('content-loaded');
    });
    moduleEmitter.on('content-load-progress', (progress) => {
        emitter.emit('content-load-progress', progress);
    });

    const api: RendererApi = {
        on: emitter.on,
        off: emitter.off,
        loadContent(path: string, audioPath?: string) {
            return new Promise<void>(async (resolve) => {
                try {
                    await loadArtwork(path, audioPath);
                    resolve();
                } catch (error) {
                    console.error('Failed to load content', error);
                }
            });
        },
        pauseTracking() {
            emitter.emit('pause-tracking');
        },
        resumeTracking() {
            emitter.emit('resume-tracking');
        },
        pauseAudio() {
            if (audio) {
                audio.context.suspend();
            }
        },
        resumeAudio() {
            if (audio) {
                audio.context.resume();
            }
        },

        // TODO return a promise
        startRecording() {
            mediaRecorder.recordVideo({
                onStart() {
                    emitter.emit('recording-started');
                },
                onStop() {
                    emitter.emit('recording-stopped');
                },
                onProcessFrame(model) {
                    emitter.emit('recording-progress', {
                        elapsedSeconds: model.elapsedTimeMs / 1000,
                        totalSeconds: model.maxRecordingMs / 1000,
                    });
                    drawWatermark(model.ctx);
                },
                onVideoReady(model) {
                    emitter.emit('recording-ready', model.videoBlob);
                },
            });
        },
        stopRecording() {
            mediaRecorder.stopRecording();
        },
    };

    return api;
}
