import * as THREE from 'three';
import {
    I8thWallImageTargetEventModel,
    I8thWallOnStartModel,
    IPipelineModule,
} from '.';
import mitt, { Emitter } from 'mitt';
import { ACESFilmicToneMapping, EquirectangularReflectionMapping } from 'three';
import { RGBELoader } from 'three/examples/jsm/Addons.js';

window.THREE = THREE;

export type ImageTargetEvents = {
    'on-show-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-update-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-update-scene': void;
    'on-hide-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-targets-scanned': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-render': void;
    'content-load-progress': number;
    'content-loaded': void;
    setup: {
        scene: THREE.Scene;
        camera: THREE.Camera;
    };
    'resume-tracking': void;
    'pause-tracking': void;
};

const emitter = mitt<ImageTargetEvents>();

export type ImageTargetPipelineModuleResult = IPipelineModule & {
    emitter: Emitter<ImageTargetEvents>;
};

export const imageTargetPipelineModule = (): any => {
    const targetFound = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-show-target', { detail });
    };

    const updateTarget = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-update-target', { detail });
    };

    const hideTarget = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-hide-target', { detail });
    };

    const imageLoading = ({ detail }: I8thWallImageTargetEventModel) => {
        console.log('image targets: ', detail);
    };

    return {
        name: 'image-target',
        emitter,
        onStart: ({ canvas }: Partial<I8thWallOnStartModel>) => {

            // @ts-expect-error: error for XR8 on window
            const { scene, camera, renderer } = XR8.Threejs.xrScene();
            camera.position.set(0, 3, 0);

            // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
            // scene.add(directionalLight);
            // directionalLight.position.x = 2.5;
            // directionalLight.position.y = 5;
            // directionalLight.position.z = 3.5;

            // For our clipping planes we add later
            renderer.capabilities.logarithmicDepthBuffer = true;
            renderer.localClippingEnabled = true;

            // Add some light to the scene.
            renderer.physicallyCorrectLights = true;
            renderer.toneMapping = ACESFilmicToneMapping;
            renderer.toneMappingExposure = 1;

            new RGBELoader().load(
                '/empty_warehouse_01_1k.hdr',
                function (texture) {
                    texture.mapping = EquirectangularReflectionMapping;

                    scene.environment = texture;
                },
            );

            emitter.emit('setup', { scene, camera });

            // prevent scroll/pinch gestures on canvas
            canvas?.addEventListener('touchmove', (event) => {
                event.preventDefault();
            });

            // Sync the xr controller's 6DoF position and camera paremeters with our scene.
            // @ts-expect-error: error for XR8 on window
            XR8.XrController.updateCameraProjectionMatrix({
                origin: camera.position,
                facing: camera.quaternion,
            });
        },
        onUpdate: () => {
            emitter.emit('on-update-scene');
        },
        onRender: () => {
            emitter.emit('on-render');
        },
        onException: (error: Error) => {
            console.log('Image target pipeline module error: ', error);
        },

        listeners: [
            { event: 'reality.imageloading', process: imageLoading },
            { event: 'reality.imagefound', process: targetFound },
            { event: 'reality.imageupdated', process: updateTarget },
            { event: 'reality.imagelost', process: hideTarget },
        ],
    };
};
