   uniform sampler2D videoTexture;
        uniform float videoHasAlpha;
        uniform float videoLoaded;
        uniform float timeElapsed;
        uniform float timeMark;
        varying vec2 vUv;
        
        float map(float value, float inputMin, float inputMax, float outputMin, float outputMax) {
            float mapValue = ((value - inputMin) / (inputMax - inputMin) * (outputMax - outputMin) + outputMin);
            return clamp(mapValue, 0.0, 1.0);
        }
        
        float quadraticOut(float t) {
          return -t * (t - 2.0);
        }
        
        float circle(vec2 _st, float _radius) {
            vec2 dist = _st - vec2(0.5);
          return 1.0 - smoothstep(
                _radius - (_radius * 0.01),
                _radius + (_radius * 0.01),
                dot(dist, dist) * 4.0
            );
        }
  
        vec4 alphaBlend( vec4 src, vec4 dst ) {
          float final_alpha = src.a + dst.a * (1.0 - src.a);
          if( final_alpha == 0.0 ) {
            return vec4( 0.0, 0.0, 0.0, 0.0 );
          }
          return vec4( (src.rgb * src.a + dst.rgb * dst.a * (1.0 - src.a)) / final_alpha, final_alpha);
        }      
        
        void main(void) {
            vec4 videoColor = vec4(0.0, 0.0, 0.0, 0.0);
            if(videoLoaded > 0.5) {
                if(videoHasAlpha > 0.5) {
                    vec3 tColor = texture2D( videoTexture, vec2( vUv.x * 0.5,       vUv.y ) ).rgb;
                    vec3 aColor = texture2D( videoTexture, vec2( vUv.x * 0.5 + 0.5, vUv.y ) ).rgb;
                    videoColor = vec4(tColor, aColor.r);
                } else {
                    vec4 color = texture2D( videoTexture, vec2( vUv.x, vUv.y ) );
                    videoColor = color;
                }
            }
            vec4 circleColor = vec4(0.0, 0.0, 0.0, 0.0);
            float anim = quadraticOut( map(timeElapsed, timeMark, timeMark + 0.4, 0.0, 1.0) );
            if( anim < 1.0 )  {
              float circleSize = map(anim, 0.0, 1.0, 0.05, 0.40);
              float circleShape = circle(vUv, circleSize);
              float circleAlpha = map(anim, 0.0, 1.0, 0.6, 0.0);
              circleColor = vec4(vec3(0.83, 0.35, 0.33), circleShape * circleAlpha);
            }
            
            gl_FragColor = alphaBlend(videoColor, circleColor);
        } 